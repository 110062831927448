/* You can add global styles to this file, and also import other style files */
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; position: relative; }

.example-full-width {
  width: 100%;
}

.mat-mdc-dialog-content {
  min-width: 50vw;
}

.mat-column-acoes {
  width: 160px;
  text-align: center;
}

.form-label {
  margin-bottom: 0;   
}

.mat-mdc-select-disabled .mat-mdc-select-value,
.mdc-text-field--disabled .mdc-text-field__input {
  color: black !important;
}

.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: block !important;
}